import React, { Fragment } from "react";
import HeroPhone from "../img/home-hero-phone.png";
import AppStoreBadge from "../img/Mobile app store badge.svg";
import PlayStoreBadge from "../img/Mobile app store badge-1.svg";
import AppGalleryBadge from "../img/Mobile app store badge-2.svg";
import OfferImage from "../img/special_offer.svg";
import TestimonialCompanyLogo from "../img/testimonial-company-logo.svg";
import TestimonialPersonImage from "../img/testimonial-person-picture.png";
import HowItWorks from "../img/how-it-works-graph.svg";
import DemoLaptop from "../img/demo-laptop.png";
import Partners from "./sections/Partners";
import TripPayCreditCard from "./sections/TripPayCreditCard";
import Goals from "./sections/Goals";
import Metrics from "./sections/Metrics";

function Home() {
  return (
    <Fragment>
      <section className="hero-header-section">
        <div className="inner-container">
          <div className="body">
            <div className="pill">Your passport to a seamless journey!</div>
            <h1>
              One app, <br />
              <span>Takes you further</span>
            </h1>
            <p>
              TripPay is a revolutionary fintech application that redefines the
              travel experience.
              <br />
              <br />
              Our platform simplifies financial transactions, travel planning,
              and management, empowering travelers to focus on their journeys.
            </p>
            <ul className="app-download">
              <li>
                <img src={AppStoreBadge} alt=""></img>{" "}
              </li>
              <li>
                <img src={PlayStoreBadge} alt=""></img>{" "}
              </li>
              <li>
                <img src={AppGalleryBadge} alt=""></img>{" "}
              </li>
            </ul>
          </div>
          <img src={HeroPhone} className="home-hero-phone" alt=""></img>
        </div>
      </section>
      <Goals />
      <Partners />
      <section className="offers-section with-brush-bg">
        <div className="inner-container">
          <div className="headline headline-xl center">
            <h1>Best Offers</h1>
            <p>
              Simple, transparent pricing that grows with you. Try any plan free
              for 30 days.
            </p>
          </div>
          <ul className="offers-list">
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
          </ul>
        </div>
      </section>
      <section className="testimonials-section">
        <div className="inner-container">
          <ul className="testimonials-list">
            <li className="testimonial-item">
              <img
                src={TestimonialCompanyLogo}
                className="testimonial-company-logo"
                alt=""
              ></img>
              <p className="testimonial-text">
                We'll no doubt be working with us on future projects. Finding
                the right agency to work with is a daunting task, but we
                absolutely found the right team and haven’t looked back.
              </p>
              <img
                src={TestimonialPersonImage}
                className="testimonial-person-picture"
                alt=""
              ></img>
              <span className="testimonial-person-name">Mohamed Alsolami</span>
              <span className="testimonial-person-job-title">
                Product Manager, STC
              </span>
            </li>
          </ul>
        </div>
      </section>
      <section className="how-it-works-section">
        <div className="inner-container">
          <div className="headline headline-xl">
            <span>Features</span>
            <h1>How it works </h1>
            <p>Control Your Spending as a Traveler</p>
          </div>
          <img src={HowItWorks} className="graph" alt=""></img>
        </div>
      </section>
      <TripPayCreditCard />
      <Metrics />
      <section className="demo-section">
        <div className="inner-container">
          <div className="demo-headline-wrapper">
            <div className="headline headline-xl">
              <h1>DEMO for agency</h1>
              <p>
                We’re still building. Subscribe for updates and 20% off when we
                launch. No spam, we promise!
              </p>
            </div>

            <form action="" method="post">
              <input
                type="text"
                placeholder="Enter your email"
                className="textbox demo-textbox"
              ></input>
              <input
                type="submit"
                value="REQUEST DEMO"
                className="btn primary-btn demo-submit-btn"
              ></input>
            </form>
            <p className="data-privacy-hint">
              We care about your data in our{" "}
              <a href="/Privacy">privacy policy</a>.
            </p>
          </div>
          <img src={DemoLaptop} className="demo-laptop" alt=""></img>
        </div>
      </section>
      <section className="banner-section">
        <div className="inner-container">
          <div className="banner-wrapper">
            <div className="headline headline-xl">
              <h1>Join 2,000+ subscribers</h1>
              <p>Stay in the loop with everything you need to know.</p>
            </div>
            <form action="" method="post">
              <input
                type="text"
                placeholder="Enter your email"
                className="textbox banner-textbox"
              ></input>
              <input
                type="submit"
                value="Subscribe"
                className="btn primary-btn banner-submit-btn"
              ></input>
              <p className="data-privacy-hint">
                We care about your data in our{" "}
                <a href="/Privacy">privacy policy</a>.
              </p>
            </form>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Home;
