import React, { Fragment } from "react";
import PageTitle from "./sections/PageTitle";
import Goals from "./sections/Goals";
import TripPayCreditCard from "./sections/TripPayCreditCard";
import Metrics from "./sections/Metrics";
import OverviewWebMobileImage from "../img/overview_web_mobile.png";
import FeaturedIcon1 from "../img/featured_icon_1.svg";
import FeaturedIcon2 from "../img/featured_icon_2.svg";
import FeaturedIcon3 from "../img/featured_icon_3.svg";
import BuildingImage from "../img/Building.png";
import ServiceIcon1 from "../img/services_icon_1.svg";
import ServiceIcon2 from "../img/services_icon_2.svg";
import ServiceIcon3 from "../img/services_icon_3.svg";
// import Goals2 from "./sections/Goals2";
function Services() {
  return (
    <Fragment>
      <PageTitle
        Title="Services"
        SubTitle="TripPay is a revolutionary fintech application that redefines the travel experience."
      />
      <section className="services-section">
        <div className="inner-container">
          <div className="headline headline-xl">
            <span>Partners</span>
            <h1>Our Services</h1>
            <p>
              Consolidate A/R: Unify revenue from all online sales channels,
              simplifying financial management.
            </p>
          </div>
          <div className="services">
            <ul className="services-list">
              <li className="services-item">
                <strong>
                  <img src={ServiceIcon1} alt=""></img>
                  Track Services
                </strong>
                <p>
                  Track Services and Set Aside Money in Real-Time Reservations:
                  Easily manage bookings, track services, and allocate funds on
                  the spot, ensuring a seamless experience for your clients.
                </p>
                <a href="/">Learn more</a>
              </li>
              <li className="services-item">
                <strong>
                  <img src={ServiceIcon2} alt=""></img>
                  Customized Itinerary Planning
                </strong>
                <p>
                  TripPay provides travel agencies with a powerful tool for
                  creating and sharing customized itineraries for their clients.
                </p>
                <a href="/">Learn more</a>
              </li>
              <li className="services-item">
                <strong>
                  <img src={ServiceIcon3} alt=""></img>
                  Real-Time Group Management
                </strong>
                <p>
                  For travel agencies handling group bookings, TripPay offers
                  real-time group management features. Agencies can efficiently
                  manage group reservations, track group member information, and
                  communicate updates instantly.
                </p>
                <a href="/">Learn more</a>
              </li>
            </ul>
            <img src={BuildingImage} alt=""></img>
          </div>
        </div>
      </section>
      <Goals />
      <section className="goals-2-section">
        <div className="inner-container">
          <div className="headline headline-xxl center">
            <span className="pill">Features</span>
            <h1>Our Goals</h1>
            <p>Control Your Spending as a Traveler</p>
          </div>
          <img src={OverviewWebMobileImage} alt=""></img>
          <ul className="goals-list">
            <li className="goals-item">
              <img src={FeaturedIcon1} alt=""></img>
              <strong>Payment Processing Integration</strong>
              <p>
                TripPay integrates with payment processing solutions to
                facilitate secure and convenient transactions for travel
                agencies.
              </p>
              <a href="/">Learn more</a>
            </li>
            <li className="goals-item">
              <img src={FeaturedIcon2} alt=""></img>
              <strong>Marketing and Promotions</strong>
              <p>
                Travel agencies can leverage TripPay's marketing and promotions
                support to reach a wider audience.
              </p>
              <a href="/">Learn more</a>
            </li>
            <li className="goals-item">
              <img src={FeaturedIcon3} alt=""></img>
              <strong>Customized Itinerary Planning</strong>
              <p>
                TripPay provides travel agencies with a powerful tool for
                creating and sharing customized itineraries for their clients.
              </p>
              <a href="/">Learn more</a>
            </li>
          </ul>
        </div>
      </section>
      <Metrics />
      <TripPayCreditCard />
    </Fragment>
  );
}

export default Services;
