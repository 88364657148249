import React, { Fragment } from "react";
import Partners from "./sections/Partners";
import TripPayCreditCard from "./sections/TripPayCreditCard";
import PageTitle from "./sections/PageTitle";
import GoalIcon1 from "../img/Goal_icon_1.svg";
import GoalIcon2 from "../img/Goal_icon_2.svg";
import GoalIcon3 from "../img/Goal_icon_3.svg";
import GoalIcon4 from "../img/Goal_icon_4.svg";
import ValuesIcon1 from "../img/Values_icon_1.svg";
import ValuesIcon2 from "../img/Values_icon_2.svg";
import ValuesIcon3 from "../img/Values_icon_3.svg";
import DemoLaptop from "../img/demo-laptop.png";

function Business() {
  return (
    <Fragment>
      <PageTitle
        Title="Business"
        SubTitle="TripPay is a revolutionary fintech application that redefines the
            travel experience."
      />
      <section className="objectives-section">
        <div className="inner-container">
          <div className="headline headline-xl">
            <span>Our Objectives</span>
            <h1>
              TripPay your passport to a seamless and unforgettable journey!
            </h1>
            <p>
              TripPay transforms travel globally, with a focus on Islamic
              countries, the Middle East, and North Africa (MENA) region,
              starting with Saudi Arabia and Gulf Countries.
            </p>
          </div>
          <div className="objectives">
            <ul className="objectives-list">
              <li className="objectives-item active">
                <strong>Transportation solutions</strong>
                <p>
                  Whether you have a team of 2 or 200, our shared team inboxes
                  keep everyone on the same page and in the loop.
                </p>
                <a href="/">Learn more</a>
              </li>
              <li className="objectives-item">
                <strong>Rewards and loyalty programs</strong>
                <p>
                  An all-in-one customer service platform that helps you balance
                  everything your customers need to be happy.
                </p>
                <a href="/">Learn more</a>
              </li>
              <li className="objectives-item">
                <strong>Flight and hotel booking</strong>
                <p>
                  Measure what matters with Untitled’s easy-to-use reports. You
                  can filter, export, and drilldown on the data in a couple
                  clicks.
                </p>
                <a href="/">Learn more</a>
              </li>
            </ul>
            <img src={DemoLaptop} alt=""></img>
          </div>
        </div>
      </section>
      <Partners />
      <TripPayCreditCard />
      <section className="goals-section">
        <div className="inner-container">
          <div className="headline headline-xl">
            <span>Features</span>
            <h1>Goals</h1>
            <p>Control Your Spending as a Traveler</p>
          </div>
          <div className="goals">
            <ul className="goals-list">
              <li className="goals-item">
                <img src={GoalIcon1} alt=""></img>
                <strong>Manage Your Accounts</strong>
                <p>
                  TripPay provides you with the flexibility to handle your
                  finances while traveling.
                </p>
              </li>
              <li className="goals-item">
                <img src={GoalIcon2} alt=""></img>
                <strong>Track Expenses</strong>
                <p>
                  Keep an eye on your expenses with TripPay's expense tracker,
                  helping you stay within budget.
                </p>
              </li>
              <li className="goals-item">
                <img src={GoalIcon3} alt=""></img>
                <strong>Funds in Real-Time</strong>
                <p>
                  Allocate funds to different purposes instantly, ensuring
                  you're always in control.
                </p>
              </li>
              <li className="goals-item">
                <img src={GoalIcon4} alt=""></img>
                <strong>Expense Tracker</strong>
                <p>
                  Make informed financial decisions by monitoring your spending
                  patterns.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="banner-section">
        <div className="inner-container">
          <div className="banner-wrapper">
            <div className="headline headline-xl">
              <h1>Join 2,000+ subscribers</h1>
              <p>Stay in the loop with everything you need to know.</p>
            </div>
            <form action="" method="post">
              <input
                type="text"
                placeholder="Enter your email"
                className="textbox banner-textbox"
              ></input>
              <input
                type="submit"
                value="Subscribe"
                className="btn primary-btn banner-submit-btn"
              ></input>
              <p className="data-privacy-hint">
                We care about your data in our <a href="/">privacy policy</a>.
              </p>
            </form>
          </div>
        </div>
      </section>
      <section className="values-section">
        <div className="inner-container">
          <ul className="values-list">
            <li className="values-item">
              <img src={ValuesIcon1} alt=""></img>
              <strong>Our Goals</strong>
              <p>
                Provide a comprehensive platform for seamless travel and
                financial management.
              </p>
            </li>
            <li className="values-item">
              <img src={ValuesIcon2} alt=""></img>
              <strong>Our Vision</strong>
              <p>
                Our vision is to become the global travel companion, making
                travel effortless and enriching.
              </p>
            </li>
            <li className="values-item">
              <img src={ValuesIcon3} alt=""></img>
              <strong>Our Mission</strong>
              <p>
                Our mission is to make your travels smoother, safer, and more
                enjoyable.
              </p>
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}

export default Business;
