import React from "react";

function PageTitle(prop) {
  let { Title, SubTitle } = prop;
  return (
    <section className="page-title-section">
      <div className="inner-container">
        <h1>{Title}</h1>
        <p>{SubTitle}</p>
      </div>
    </section>
  );
}

export default PageTitle;
