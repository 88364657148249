import { Footer, Header } from "./components/";
import { Outlet } from "react-router-dom";
import { Fragment, Component } from "react";
import UserContext from "./contexts/UserContext";
import Cookies from "js-cookie";

class App extends Component {
  componentDidMount() {
    let user = JSON.parse(Cookies.get("user") || null);
    this.setState(user);
  }
  render() {
    return (
      <Fragment>
        <UserContext.Provider value={{ ...this.state }}>
          <Header />
          <Outlet />
          <Footer />
        </UserContext.Provider>
      </Fragment>
    );
  }
}

export default App;
