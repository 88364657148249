import React from "react";
import TripPayCreditCardImage from "../../img/trippay-credit-card.png";
function TripPayCreditCard() {
  return (
    <section className="credit-card-section">
      <div className="inner-container">
        <div className="credit-card">
          <div className="credit-card-features">
            <div className="headline headline-xl">
              <h1>Trippay credit card</h1>
              <p>oh yeah ,,, let us have this fingerprint</p>
            </div>

            <ul>
              <li className="credit-card-feature">Contactless Payments:</li>
              <li className="credit-card-feature">
                Mobile Wallet Integration:
              </li>
              <li className="credit-card-feature">Budgeting Tools</li>
              <li className="credit-card-feature">Biometric Authentication</li>
              <li className="credit-card-feature">Customer Support</li>
              <li className="credit-card-feature">Virtual Card Options</li>
            </ul>
          </div>
          <img
            src={TripPayCreditCardImage}
            className="trippay-credit-card-img"
            alt="Trippay credit card"
          />
        </div>
      </div>
    </section>
  );
}

export default TripPayCreditCard;
