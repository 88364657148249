import React, { Fragment } from "react";
import PageTitle from "./sections/PageTitle";
import Metrics from "./sections/Metrics";
import DemoImage from "../img/traveler_demo.png";
import TravelerImage1 from "../img/traveler_image_1.png";
import TravelerImage2 from "../img/traveler_image_2.png";
import TravelerImage3 from "../img/traveler_image_3.png";
import TravelerIcon1 from "../img/traveler_icon_1.svg";
import TravelerIcon2 from "../img/traveler_icon_2.svg";
import TravelerIcon3 from "../img/traveler_icon_3.svg";

function Traveler() {
  return (
    <Fragment>
      <PageTitle
        Title="Traveler"
        SubTitle="TripPay is a revolutionary fintech application that redefines the travel experience."
      />
      <section className="goals-3-section  with-brush-bg">
        <div className="inner-container">
          <div className="headline-wrapper">
            <div className="headline headline-xl">
              <span>TripPay</span>
              <h1>Our Goals</h1>
              <p>Control Your Spending as a Traveler</p>
            </div>
            <ul className="featured-list">
              <li className="featured-item featured-item-1">
                <strong>Enhance travel experiences</strong>
                <p>
                  Redefine and enhance travel experiences for individuals and
                  businesses.
                </p>
              </li>
              <li className="featured-item featured-item-2">
                <strong>Financial management</strong>
                <p>
                  Provide a comprehensive platform for seamless travel and
                  financial management.
                </p>
              </li>
            </ul>
          </div>
          <img src={DemoImage} alt="" className="demo-image"></img>
        </div>
      </section>
      <section className="travelers-section with-brush-bg">
        <div className="inner-container">
          <div className="headline headline-xl center">
            <span>Our Services</span>
            <h1>Travelers</h1>
            <p>Control Your Spending as a Traveler</p>
          </div>
          <ul className="traveler-feature-list">
            <li className="traveler-feature-item">
              <div className="traveler-feature-info">
                <img src={TravelerIcon1} alt="" className="icon"></img>
                <strong>Our Objectives</strong>
                <p>
                  Establish TripPay as a trusted financial companion for
                  tourists, visitors, and Hajj and Umrah pilgrims.
                </p>
                <ul>
                  <li>
                    Secure international money transfers using blockchain
                    technology
                  </li>
                  <li>Flight and hotel booking</li>
                  <li>Translation and language tools</li>
                </ul>
              </div>
              <img src={TravelerImage1} alt=""></img>
            </li>
            <li className="traveler-feature-item">
              <img src={TravelerImage2} alt=""></img>
              <div className="traveler-feature-info">
                <img src={TravelerIcon2} alt="" className="icon"></img>
                <strong>Our Services for Travelers</strong>
                <p>
                  Cater to travelers worldwide with diverse services and
                  features.
                </p>
                <ul>
                  <li>
                    Consolidate A/R: Unify revenue from all online sales
                    channels, simplifying financial management.
                  </li>
                  <li>
                    AutoPay: Streamline disbursements, ensuring reliable and
                    timely payments.
                  </li>
                  <li>
                    Trip Pay: Customize disbursements to multiple accounts based
                    on rules you create.
                  </li>
                </ul>
              </div>
            </li>
            <li className="traveler-feature-item">
              <div className="traveler-feature-info">
                <img src={TravelerIcon3} alt="" className="icon"></img>
                <strong>Our Services for Business</strong>
                <p>
                  At TripPay, we believe travel should be an unforgettable
                  journey, not a financial hassle.
                </p>
                <ul>
                  <li>Shopping and e-commerce</li>
                  <li>Travel safety and alerts</li>
                  <li>Health and wellness resources</li>
                </ul>
              </div>
              <img src={TravelerImage3} alt=""></img>
            </li>
          </ul>
        </div>
      </section>
      <Metrics />
    </Fragment>
  );
}

export default Traveler;
