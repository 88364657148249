import React, { Fragment, useRef, useState } from "react";
import Cookies from "js-cookie";

import Phone from "../img/phone.png";
import Auth from "../utils/auth";
function Login() {
  let [errors, setErrors] = useState([]);
  let email = useRef("");
  let password = useRef("");
  return (
    <Fragment>
      <section className="login-section">
        <div className="login-form-wrapper">
          <div className="login-form-inner-wrapper">
            <div className="headline headline-xl">
              <h1>Login</h1>
              <p>Welcome back! Please enter your details.</p>
            </div>
            {errors.length > 0 &&
              errors.map((error, i) => (
                <span className="info-message error" key={i}>
                  {error}
                </span>
              ))}
            <form action="" method="">
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                className="textbox"
                ref={email}
              ></input>
              <label htmlFor="pwd">Password</label>
              <input
                id="password"
                type="password"
                placeholder="Enter your password"
                className="textbox"
                ref={password}
              ></input>
              <div className="password-options">
                <div className="remember-passowrd-wrapper">
                  <input type="checkbox"></input>
                  <label>Remember for 30 days</label>
                </div>
                <a href="/">Forgot password ?</a>
              </div>
              <input
                type="submit"
                value={"Sign in"}
                className="btn primary-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setErrors([]);
                  Auth.signin({
                    email: email.current.value,
                    password: password.current.value,
                    errorCallback: setErrors,
                    successCallback: (result) => {
                      Cookies.set("user", JSON.stringify(result.user));
                      window.location = "/Home";
                    },
                  });
                }}
              ></input>
              <input
                type="submit"
                value={"Sign in with Google"}
                className="btn secondary-btn"
              ></input>
              <input
                type="submit"
                value={"Sign in with Facebook"}
                className="btn secondary-btn"
              ></input>
              <input
                type="submit"
                value={"Sign in with Apple"}
                className="btn secondary-btn"
              ></input>
              <input
                type="submit"
                value={"Sign in with X"}
                className="btn secondary-btn"
              ></input>
            </form>
            <p className="login-hint">
              Don’t have an account? <a href="/Signup">Sign up</a>
            </p>
          </div>
        </div>
        <div className="image-wrapper">
          <img src={Phone} alt=""></img>
        </div>
      </section>
    </Fragment>
  );
  // }
}

export default Login;
