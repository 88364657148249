import React from "react";

function Metrics() {
  return (
    <section className="metrics-section">
      <div className="inner-container">
        <div className="headline headline-xl center">
          <h1>Great products, faster than ever</h1>
          <p>Everything you need to build modern and great products.</p>
        </div>
        <ul className="metrics-list">
          <li className="metrics-item">
            <span className="metrics-value">400+</span>
            <span className="metrics-title">Clients</span>
          </li>
          <li className="metrics-item">
            <span className="metrics-value">600+</span>
            <span className="metrics-title">Partners</span>
          </li>
          <li className="metrics-item">
            <span className="metrics-value">10k</span>
            <span className="metrics-title">Services</span>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Metrics;
