import React, { Fragment } from "react";
import Partner1 from "../img/الجمارك السعودية.png";
import Partner2 from "../img/الموارد البشريه السعودية.png";
import Partner3 from "../img/بنك الأهلي.png";
import Partner4 from "../img/بنك البلاد.png";
import ContactForm from "./sections/ContactForm";

function Partnership() {
  return (
    <Fragment>
      <section className="partnership-section with-brush-bg">
        <div className="inner-container">
          <div className="headline headline-xl center">
            <h1>Partnership</h1>
            <p>
              Want to work with some of the best global talent and build
              software used by all the companies you know and love? Join the
              team — we’re hiring!
            </p>
          </div>
          <div className="partners">
            <ul className="partner-list">
              <li className="partner">
                <img src={Partner1} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner2} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner3} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner4} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner1} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner2} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner3} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner4} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner1} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner2} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner3} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner4} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner1} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner2} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner3} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner4} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner1} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner2} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner3} alt=""></img>
              </li>
              <li className="partner">
                <img src={Partner4} alt=""></img>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactForm />
    </Fragment>
  );
}

export default Partnership;
