import React, { Fragment } from "react";
import PageTitle from "./sections/PageTitle";
import AboutImage1 from "../img/about_image_1.png";
import AboutImage2 from "../img/about_image_2.png";
import AboutImage3 from "../img/about_image_3.png";
import TeamMemberAvatar1 from "../img/team_member_avatar_1.png";
import TeamMemberAvatar2 from "../img/team_member_avatar_2.png";
import TeamMemberAvatar3 from "../img/team_member_avatar_3.png";
import TeamMemberAvatar4 from "../img/team_member_avatar_4.png";
import Partners from "./sections/Partners";
function About() {
  return (
    <Fragment>
      <PageTitle
        Title="About"
        SubTitle="TripPay is a revolutionary fintech application that redefines the
            travel experience."
      />
      <section className="about-section  with-brush-bg">
        <div className="inner-container">
          <div className="headline headline-xl center">
            <h1>We’re a mission driven company</h1>
            <p>
              Powerful, self-serve product and growth analytics to help you
              convert, engage, and retain more users. Trusted by over 4,000
              startups.
            </p>
          </div>
        </div>
        <ul className="about-list">
          <li className="about-item">
            <div className="about-item-description">
              <strong>Mission and Vision</strong>
              <p>
                TripPay integrates with payment processing solutions to
                facilitate secure and convenient transactions for travel
                agencies.
              </p>
            </div>
            <img src={AboutImage1} alt=""></img>
          </li>
          <li className="about-item">
            <img src={AboutImage2} alt=""></img>
            <div className="about-item-description">
              <strong>Unique Selling Proposition (USP)</strong>
              <p>
                TripPay is a revolutionary fintech application that redefines
                the travel experience. Our platform simplifies financial
                transactions, travel planning, and management, empowering
                travelers to focus on their journeys. With a wide range of
                services
              </p>
            </div>
          </li>
          <li className="about-item">
            <div className="about-item-description">
              <strong>Awards and Recognitions</strong>
              <p>
                TripPay transforms travel globally, with a focus on Islamic
                countries, the Middle East, and North Africa (MENA) region,
                starting with Saudi Arabia and Gulf Countries.
              </p>
            </div>
            <img src={AboutImage3} alt=""></img>
          </li>
        </ul>
      </section>
      <section className="team-section with-brush-bg">
        <div className="inner-container">
          <div className="headline headline-xl center">
            <span>We’re hiring!</span>
            <h1>Meet our team</h1>
            <p>
              Our philosophy is simple — hire a team of diverse, passionate
              people and foster a culture that empowers you to do your best
              work.
            </p>
          </div>
          <ul className="team-members">
            <li className="team-member">
              <img
                src={TeamMemberAvatar1}
                alt=""
                className="team-member-avatar"
              ></img>
              <strong className="team-member-name">Mohamed Al Solami</strong>
              <span className="team-member-job-title">Founder & CEO</span>
              <ul className="social-media">
                <li className="social-media-item social-media-x">
                  <a href="/">X</a>
                </li>
                <li className="social-media-item social-media-linkedin">
                  <a href="/">LinkedIn</a>
                </li>
                <li className="social-media-item social-media-dribbble">
                  <a href="/">Dribbble</a>
                </li>
              </ul>
            </li>
            <li className="team-member">
              <img
                src={TeamMemberAvatar2}
                alt=""
                className="team-member-avatar"
              ></img>
              <strong className="team-member-name">Mohamed Ahmed</strong>
              <span className="team-member-job-title">Engineering Manager</span>
              <ul className="social-media">
                <li className="social-media-item social-media-x">
                  <a href="/">X</a>
                </li>
                <li className="social-media-item social-media-linkedin">
                  <a href="/">LinkedIn</a>
                </li>
                <li className="social-media-item social-media-dribbble">
                  <a href="/">Dribbble</a>
                </li>
              </ul>
            </li>
            <li className="team-member">
              <img
                src={TeamMemberAvatar3}
                alt=""
                className="team-member-avatar"
              ></img>
              <strong className="team-member-name">Soliman Mohamed</strong>
              <span className="team-member-job-title">Product Manager</span>
              <ul className="social-media">
                <li className="social-media-item social-media-x">
                  <a href="/">X</a>
                </li>
                <li className="social-media-item social-media-linkedin">
                  <a href="/">LinkedIn</a>
                </li>
                <li className="social-media-item social-media-dribbble">
                  <a href="/">Dribbble</a>
                </li>
              </ul>
            </li>
            <li className="team-member">
              <img
                src={TeamMemberAvatar4}
                alt=""
                className="team-member-avatar"
              ></img>
              <strong className="team-member-name">Houda Ahmed</strong>
              <span className="team-member-job-title">Frontend Developer</span>
              <ul className="social-media">
                <li className="social-media-item social-media-x">
                  <a href="/">X</a>
                </li>
                <li className="social-media-item social-media-linkedin">
                  <a href="/">LinkedIn</a>
                </li>
                <li className="social-media-item social-media-dribbble">
                  <a href="/">Dribbble</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <Partners />
    </Fragment>
  );
}

export default About;
