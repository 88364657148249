import Cookies from "js-cookie";
import jQuery from "jquery";

let Auth = {
  async signin(data) {
    let { email, password, errorCallback, successCallback } = data;
    if (email.length === 0 || password.length === 0) return;
    jQuery
      .ajax({
        method: "POST",
        url: "https://api.trippay.com/api/login",
        data: {
          email,
          password,
        },
      })
      .done((result) => successCallback && successCallback(result))
      .fail(
        (e) =>
          errorCallback &&
          errorCallback(
            e.responseJSON?.errors || [e.responseJSON?.message] || []
          )
      );
  },
  async signup(data) {
    let { name, email, password, errorCallback, successCallback } = data;
    if (name.length === 0 || email.length === 0 || password.length === 0)
      return;
    jQuery
      .ajax({
        method: "POST",
        url: "https://api.trippay.com/api/register",
        data: {
          name,
          email,
          password,
        },
      })
      .done((result) => successCallback && successCallback(result))
      .fail(
        (e) => errorCallback && errorCallback(e.responseJSON?.errors || [])
      );
  },
  logout() {
    Cookies.set("user", "");
    window.location = "/";
  },
};
export default Auth;
