import React, { Fragment, useContext } from "react";
import userContext from "../contexts/UserContext";
import Auth from "../utils/auth";
function Header() {
  function toggleMenu() {
    document.querySelector(".nav-list").classList.toggle("visible");
  }
  let user = useContext(userContext);
  return (
    <header>
      <div className="top-bar">
        <div className="inner-container">
          <div className="left-section">
            <ul className="social-media">
              <li className="social-media-item social-media-x">
                <a href="/">X</a>
              </li>
              <li className="social-media-item social-media-linkedin">
                <a href="/">LinkedIn</a>
              </li>
              <li className="social-media-item social-media-snapchat">
                <a href="/">Snapchat</a>
              </li>
              <li className="social-media-item social-media-tiktok">
                <a href="/">Tiktok</a>
              </li>
              <li className="social-media-item social-media-youtube">
                <a href="/">Youtube</a>
              </li>
              <li className="social-media-item social-media-facebook">
                <a href="/">Facebook</a>
              </li>
              <li className="social-media-item social-media-dribbble">
                <a href="/">Dribbble</a>
              </li>
            </ul>
            <a className="phone" href="/">
              8002026566
            </a>
            <a className="mail" href="/">
              info@trippay.com
            </a>
          </div>
          <div className="right-section">
            <a href="/">KSA</a>
            <a href="/">EN</a>
          </div>
        </div>
      </div>
      <nav className="main-nav">
        <div className="inner-container">
          <a href="/Home">
            <div className="logo"></div>
          </a>
          <ul className="nav-list">
            <li className="nav-item">
              <a href="/Home">Home</a>
            </li>
            <li className="nav-item">
              <a href="/Traveler">Traveler</a>
            </li>
            <li className="nav-item">
              <a href="/Business">Business</a>
            </li>
            <li className="nav-item">
              <a href="/Services">Services</a>
            </li>
            <li className="nav-item">
              <a href="/About">About</a>
            </li>
            <li className="nav-item">
              <a href="/Offers">Offers</a>
            </li>
            <li className="nav-item">
              <a href="/Help">Help</a>
            </li>
          </ul>

          <div className="login-signup">
            {(user.name && (
              <Fragment>
                <a href="/Home">{user.name}</a>
                <a
                  href="/"
                  className="logout"
                  onClick={(e) => {
                    e.preventDefault();
                    Auth.logout();
                  }}
                >
                  Log out
                </a>
              </Fragment>
            )) || (
              <Fragment>
                <a href="/Login" className="login">
                  Login
                </a>
                <a href="/Signup" className="signup">
                  Sign up
                </a>
              </Fragment>
            )}
          </div>
          <button className="hamburger-menu" onClick={toggleMenu}>
            Menu
          </button>
        </div>
      </nav>
    </header>
  );
}

export default Header;
