import React from "react";
import Partner1 from "../../img/الجمارك السعودية.png";
import Partner2 from "../../img/الموارد البشريه السعودية.png";
import Partner3 from "../../img/بنك الأهلي.png";
import Partner4 from "../../img/بنك البلاد.png";

function showNext() {
  let visiblePartners = document.querySelectorAll(".partner.visible");
  let last = visiblePartners[visiblePartners.length - 1];
  let first = visiblePartners[0];
  if (last.nextSibling.classList.contains("partner")) {
    first.classList.toggle("visible");
    first.classList.toggle("to-right");
    last.nextSibling.classList.toggle("visible");
  }
}
function showPrevious() {
  let visiblePartners = document.querySelectorAll(".partner.visible");
  let last = visiblePartners[visiblePartners.length - 1];
  let first = visiblePartners[0];
  if (first.previousSibling.classList.contains("partner")) {
    last.classList.toggle("visible");
    last.classList.toggle("to-left");
    first.previousSibling.classList.toggle("visible");
  }
}
let MaxNumberOfVisiblePartners = 3;
let partners = [
  {
    logo: Partner1,
    link: "/",
  },
  {
    logo: Partner2,
    link: "/",
  },
  {
    logo: Partner3,
    link: "/",
  },
  {
    logo: Partner4,
    link: "/",
  },
  {
    logo: Partner1,
    link: "/",
  },
  {
    logo: Partner2,
    link: "/",
  },
  {
    logo: Partner3,
    link: "/",
  },
  {
    logo: Partner4,
    link: "/",
  },
];
function Partners() {
  return (
    <section className="partners-section">
      <div className="inner-container">
        <div className="headline headline-xl center">
          <h1>Proud of our Partners</h1>
        </div>
        <div className="partners">
          <ul className="partner-list">
            {partners.length > MaxNumberOfVisiblePartners && (
              <li>
                <button className="swipe-btn swipe-left" onClick={showPrevious}>
                  Swipe Left
                </button>
              </li>
            )}
            {partners.map((partner, i) => {
              return (
                <li
                  className={"partner ".concat(
                    i < MaxNumberOfVisiblePartners ? "visible" : ""
                  )}
                  key={i}
                >
                  <a href={partner.link}>
                    <img src={partner.logo} alt=""></img>
                    <span className="read-story">Read Customer Story</span>
                  </a>
                </li>
              );
            })}
            {partners.length > MaxNumberOfVisiblePartners && (
              <li>
                <button className="swipe-btn swipe-right" onClick={showNext}>
                  Swipe Right
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Partners;
