import React, { Fragment } from "react";
import OfferImage from "../img/special_offer.svg";
import PageTitle from "./sections/PageTitle";

function Offers() {
  return (
    <Fragment>
      <PageTitle
        Title="Offers"
        SubTitle="TripPay is a revolutionary fintech application that redefines the travel experience."
      />
      <section className="offers-section">
        <div className="inner-container">
          <ul className="offers-list">
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
            <li className="offer-item">
              <img src={OfferImage} className="offer-img" alt=""></img>
              <div className="offer-info">
                <span className="offer-type">Business Offer</span>
                <span className="offer-price">$20</span>
                <span className="offer-period">per month</span>
              </div>
              <button className="btn primary-btn">Get started</button>
              <button className="btn secondary-btn">Chat to sales</button>
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}

export default Offers;
