import React, { Fragment } from "react";
import PageTitle from "./sections/PageTitle";
function Help() {
  function toggleElement(e) {
    console.log(e.target.parentElement.classList.toggle("active"));
  }
  return (
    <Fragment>
      <PageTitle
        Title="Help"
        SubTitle="TripPay is a revolutionary fintech application that redefines the
        travel experience."
      />
      <section className="help-section with-brush-bg">
        <div className="inner-container">
          <div className="headline headline-xxl center">
            <span>Support</span>
            <h1>Everything you need to know</h1>
            <p>
              Need something cleared up? Here are our most frequently asked
              questions.
            </p>
            <input type="text" className="textbox" placeholder="Search"></input>
          </div>
          <div className="faq">
            <h1>Frequently asked questions</h1>
            <p>Everything you need to know about the product and billing.</p>
            <ul className="faq-list">
              <li className="faq-item">
                <button className="faq-title" onClick={(e) => toggleElement(e)}>
                  Is there a free trial available?
                </button>
                <p className="faq-description">
                  Yes, you can try us for free for 30 days. If you want, we’ll
                  provide you with a free, personalized 30-minute onboarding
                  call to get you up and running as soon as possible.
                </p>
              </li>
              <li className="faq-item">
                <button className="faq-title" onClick={(e) => toggleElement(e)}>
                  Can I change my plan later?
                </button>
                <p className="faq-description">
                  Yes, you can try us for free for 30 days. If you want, we’ll
                  provide you with a free, personalized 30-minute onboarding
                  call to get you up and running as soon as possible.
                </p>
              </li>
              <li className="faq-item">
                <button className="faq-title" onClick={(e) => toggleElement(e)}>
                  What is your cancellation policy?
                </button>
                <p className="faq-description">
                  Yes, you can try us for free for 30 days. If you want, we’ll
                  provide you with a free, personalized 30-minute onboarding
                  call to get you up and running as soon as possible.
                </p>
              </li>
              <li className="faq-item">
                <button className="faq-title" onClick={(e) => toggleElement(e)}>
                  Can other info be added to an invoice?
                </button>
                <p className="faq-description">
                  Yes, you can try us for free for 30 days. If you want, we’ll
                  provide you with a free, personalized 30-minute onboarding
                  call to get you up and running as soon as possible.
                </p>
              </li>
              <li className="faq-item">
                <button className="faq-title" onClick={(e) => toggleElement(e)}>
                  How does billing work?
                </button>
                <p className="faq-description">
                  Yes, you can try us for free for 30 days. If you want, we’ll
                  provide you with a free, personalized 30-minute onboarding
                  call to get you up and running as soon as possible.
                </p>
              </li>
              <li className="faq-item">
                <button className="faq-title" onClick={(e) => toggleElement(e)}>
                  How do I change my account email?
                </button>
                <p className="faq-description">
                  Yes, you can try us for free for 30 days. If you want, we’ll
                  provide you with a free, personalized 30-minute onboarding
                  call to get you up and running as soon as possible.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="banner-section center">
        <div className="inner-container">
          <div className="banner-wrapper">
            <div className="headline headline-xl center">
              <h1>Still have questions?</h1>
              <p>Join over 4,000+ startups already growing with Untitled.</p>
              <a className="btn secondary-btn" href="/">
                Learn more
              </a>
              <a className="btn primary-btn" href="/">
                Get started
              </a>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Help;
