import React, { Fragment } from "react";
import ContactForm from "./sections/ContactForm";
import ClockIcon from "../img/clock_icon.svg";
import LocationIcon from "../img/location_icon.svg";
import NewTabIcon from "../img/new_tab_icon.svg";

function Careers() {
  return (
    <Fragment>
      <section className="careers-section">
        <div className="inner-container">
          <div className="headline headline-xl center">
            <h1>Open positions</h1>
            <p>
              Want to work with some of the best global talent and build
              software used by all the companies you know and love? Join the
              team — we’re hiring!
            </p>
          </div>
          <div className="careers-category-wrapper">
            <ul className="careers-category-list">
              <li className="careers-category-item active">
                <a href="/">View all</a>
              </li>
              <li className="careers-category-item">
                <a href="/">Design</a>
              </li>
              <li className="careers-category-item">
                <a href="/">Software Engineering</a>
              </li>
              <li className="careers-category-item">
                <a href="/">Customer Success</a>
              </li>
              <li className="careers-category-item">
                <a href="/">Sales</a>
              </li>
              <li className="careers-category-item">
                <a href="/">Marketing</a>
              </li>
            </ul>
          </div>
          <div className="careers-list-wrapper">
            <div className="careers-list-item">
              <div className="top-info">
                <span className="job-cat">Design</span>
                <a href="/">
                  View Job{" "}
                  <img src={NewTabIcon} alt="Open in a new tab icon"></img>
                </a>
              </div>
              <strong className="job-title">Product Designer</strong>
              <p className="job-description">
                We’re looking for a mid-level product designer to join our team.
              </p>
              <div className="job-type-wrapper">
                <span className="job-type">
                  <img src={ClockIcon} alt=""></img>
                  Remote
                </span>
                <span className="job-time">
                  <img src={LocationIcon} alt=""></img>
                  Full-time
                </span>
              </div>
            </div>

            <div className="careers-list-item">
              <div className="top-info">
                <span className="job-cat">Software Development</span>
                <a href="/">
                  View Job{" "}
                  <img src={NewTabIcon} alt="Open in a new tab icon"></img>
                </a>
              </div>
              <strong className="job-title">Engineering Manager</strong>
              <p className="job-description">
                We’re looking for a mid-level product designer to join our team.
              </p>
              <div className="job-type-wrapper">
                <span className="job-type">
                  <img src={ClockIcon} alt=""></img>
                  Remote
                </span>
                <span className="job-time">
                  <img src={LocationIcon} alt=""></img>
                  Full-time
                </span>
              </div>
            </div>

            <div className="careers-list-item">
              <div className="top-info">
                <span className="job-cat">Customer Success</span>
                <a href="/">
                  View Job{" "}
                  <img src={NewTabIcon} alt="Open in a new tab icon"></img>
                </a>
              </div>
              <strong className="job-title">Customer Success Manager</strong>
              <p className="job-description">
                We’re looking for a mid-level product designer to join our team.
              </p>
              <div className="job-type-wrapper">
                <span className="job-type">
                  <img src={ClockIcon} alt=""></img>
                  Remote
                </span>
                <span className="job-time">
                  <img src={LocationIcon} alt=""></img>
                  Full-time
                </span>
              </div>
            </div>

            <div className="careers-list-item">
              <div className="top-info">
                <span className="job-cat">Sales</span>
                <a href="/">
                  View Job{" "}
                  <img src={NewTabIcon} alt="Open in a new tab icon"></img>
                </a>
              </div>
              <strong className="job-title">Account Executive</strong>
              <p className="job-description">
                We’re looking for a mid-level product designer to join our team.
              </p>
              <div className="job-type-wrapper">
                <span className="job-type">
                  <img src={ClockIcon} alt=""></img>
                  Remote
                </span>
                <span className="job-time">
                  <img src={LocationIcon} alt=""></img>
                  Full-time
                </span>
              </div>
            </div>

            <div className="careers-list-item">
              <div className="top-info">
                <span className="job-cat">Marketing</span>
                <a href="/">
                  View Job{" "}
                  <img src={NewTabIcon} alt="Open in a new tab icon"></img>
                </a>
              </div>
              <strong className="job-title">SEO Marketing Manager</strong>
              <p className="job-description">
                We’re looking for a mid-level product designer to join our team.
              </p>
              <div className="job-type-wrapper">
                <span className="job-type">
                  <img src={ClockIcon} alt=""></img>
                  Remote
                </span>
                <span className="job-time">
                  <img src={LocationIcon} alt=""></img>
                  Full-time
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
    </Fragment>
  );
}

export default Careers;
