import React from "react";
import Feature1 from "../../img/feature_icon_1.svg";
import Feature2 from "../../img/feature_icon_2.svg";
import Feature3 from "../../img/feature_icon_3.svg";
import Feature4 from "../../img/feature_icon_4.svg";
function Goals() {
  return (
    <section className="feature-section">
      <div className="inner-container">
        <div className="headline headline-xl">
          <h1>Our Goals</h1>
          <p>TripPay your passport to a seamless and unforgettable journey!</p>
        </div>
        <ul className="feature-list">
          <li className="feature-item">
            <img src={Feature1} className="feature-img" alt=""></img>
            <span className="feature-title">Travelers</span>
            <span className="feature-description">
              Experience smooth and secure financial transactions during your
              journeys with TripPay.
            </span>
          </li>
          <li className="feature-item">
            <img src={Feature2} className="feature-img" alt=""></img>
            <span className="feature-title">For Expansion Worldwide</span>
            <span className="feature-description">
              Join TripPay in exploring new regions with a carefully crafted
              strategic plan, considering cultural, regulatory, and economic
              factors.
            </span>
          </li>
          <li className="feature-item">
            <img src={Feature3} className="feature-img" alt=""></img>
            <span className="feature-title">Business</span>
            <span className="feature-description">
              Optimize tracking and reporting with TripPay's comprehensive
              expense management solution.
            </span>
          </li>
          <li className="feature-item">
            <img src={Feature4} className="feature-img" alt=""></img>
            <span className="feature-title">Financial</span>
            <span className="feature-description">
              Explore a complete suite of financial services with TripPay,
              including savings, investments, and insurance for your full
              financial support.
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Goals;
