import React, { Fragment, useRef, useState } from "react";
import Phone from "../img/phone.png";
import Auth from "../utils/auth";

function Signup() {
  let [errors, setErrors] = useState([]);
  let [successMessage, setSuccessMessage] = useState("");
  let name = useRef("");
  let email = useRef("");
  let password = useRef("");

  return (
    <Fragment>
      <section className="signup-section">
        <div className="login-form-wrapper">
          <div className="login-form-inner-wrapper">
            <div className="headline headline-xl">
              <h1>Sign up</h1>
              <p>Start your 30-day free trial.</p>
            </div>
            {errors.length > 0
              ? errors.map((error, i) => (
                  <span className="info-message error" key={i}>
                    {error}
                  </span>
                ))
              : successMessage.length > 0 && (
                  <span className="info-message success">{successMessage}</span>
                )}
            <form action="" method="">
              <label htmlFor="email">Name*</label>
              <input
                id="name"
                type="text"
                placeholder="Enter your name"
                className="textbox"
                ref={name}
                required
              ></input>
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                className="textbox"
                ref={email}
                required
              ></input>
              <label htmlFor="pwd">Password</label>
              <input
                id="password"
                type="password"
                placeholder="Create a password"
                className="textbox"
                ref={password}
                required
              ></input>
              <div className="password-options">
                <div className="remember-passowrd-wrapper">
                  <input type="checkbox"></input>
                  <label>Remember for 30 days</label>
                </div>
                <a href="/">Forgot password ?</a>
              </div>
              <input
                type="submit"
                value={"Sign up"}
                className="btn primary-btn"
                onClick={async (e) => {
                  e.preventDefault();
                  setErrors([]);
                  Auth.signup({
                    name: name.current.value,
                    email: email.current.value,
                    password: password.current.value,
                    errorCallback: setErrors,
                    successCallback: () => {
                      setSuccessMessage("Registred successfully.");
                      setTimeout(() => (window.location = "/"), 2000);
                    },
                  });
                }}
              ></input>
              <input
                type="submit"
                value={"Sign up with Google"}
                className="btn secondary-btn"
              ></input>
              <input
                type="submit"
                value={"Sign up with Facebook"}
                className="btn secondary-btn"
              ></input>
              <input
                type="submit"
                value={"Sign up with Apple"}
                className="btn secondary-btn"
              ></input>
              <input
                type="submit"
                value={"Sign up with X"}
                className="btn secondary-btn"
              ></input>
            </form>
            <p className="login-hint">
              Already have an account? <a href="/Login">Login</a>
            </p>
          </div>
        </div>
        <div className="image-wrapper">
          <img src={Phone} alt=""></img>
        </div>
      </section>
    </Fragment>
  );
}

export default Signup;
