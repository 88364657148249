import React from "react";
import ReactDOM from "react-dom/client";
import "./css/main.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Offers from "./pages/Offers";
import Traveler from "./pages/Traveler";
import Business from "./pages/Business";
import Services from "./pages/Services";
import About from "./pages/About";
import Help from "./pages/Help";
import Legal from "./pages/Legal";
import Partnership from "./pages/Partnership";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />}></Route>
          <Route path="/Home" element={<Home />}></Route>
          <Route path="/Traveler" element={<Traveler />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/Business" element={<Business />}></Route>
          <Route path="/Services" element={<Services />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Offers" element={<Offers />}></Route>
          <Route path="/Help" element={<Help />}></Route>
          <Route path="/Legal" element={<Legal />}></Route>
          <Route path="/Partnership" element={<Partnership />}></Route>
          <Route path="/Careers" element={<Careers />}></Route>
          <Route path="/Login" element={<Login />}></Route>
          <Route path="/Signup" element={<Signup />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
