import React from "react";

function ContactForm() {
  return (
    <section className="contact-form-section">
      <div className="inner-container">
        <div className="headline headline-xl center">
          <span>Contact us</span>
          <h1>Get in touch</h1>
          <p>We’d love to hear from you. Please fill out this form.</p>
        </div>
        <form action="" method="" className="contact-form">
          <ul className="form-elements-list">
            <li className="form-element">
              <div className="name-wrapper">
                <div className="first-name-wrapper">
                  <label for="first-name">First name</label>
                  <input
                    type="text"
                    placeholder="First name"
                    className="textbox"
                  ></input>
                </div>
                <div className="last-name-wrapper">
                  <label for="last-name">Last name</label>
                  <input
                    type="text"
                    placeholder="Last name"
                    className="textbox"
                  ></input>
                </div>
              </div>
            </li>
            <li className="form-element">
              <div className="email-wrapper">
                <label for="email">Email</label>
                <input
                  type="email"
                  placeholder="you@company.com"
                  id="email"
                  className="textbox"
                ></input>
              </div>
            </li>
            <li className="form-element">
              <div className="phone-wrapper">
                <label for="phone-number">Phone number</label>
                <input
                  type="tel"
                  placeholder="+1 (555) 000-0000"
                  id="phone-number"
                  className="textbox"
                ></input>
              </div>
            </li>
            <li className="form-element">
              <div className="message-wrapper">
                <label for="message">Message</label>
                <textarea id="message" className="textbox" spellCheck="false">
                  Leave us a message...
                </textarea>
              </div>
            </li>
            <li className="form-element">
              <input type="checkbox" id="private-policy"></input>
              <label for="private-policy">
                You agree to our friendly <a href="/Privacy">privacy policy.</a>
              </label>
            </li>
          </ul>

          <button className="btn primary-btn">Send message</button>
        </form>
      </div>
    </section>
  );
}

export default ContactForm;
