import React, { Fragment } from "react";
import PageTitle from "./sections/PageTitle";
import MapImage from "../img/map.png";
import GoogleMapImage from "../img/google_map.png";
import ContactForm from "./sections/ContactForm";
function About() {
  return (
    <Fragment>
      <PageTitle
        Title="Contact us"
        SubTitle="TripPay is a revolutionary fintech application that redefines the
            travel experience."
      />
      <section className="contact-section">
        <div className="inner-container">
          <div className="headline headline-xxl center">
            <span>About us</span>
            <h1>We’re a distributed team</h1>
            <p>We have offices and teams all around the world.</p>
          </div>
          <img src={MapImage} alt="" className="map-img"></img>
          <ul className="contact-list">
            <li className="contact-item">
              <strong>Support</strong>
              <p>Our friendly team is here to help.</p>
              <a href="mailto:">support@trippay.com</a>
            </li>
            <li className="contact-item">
              <strong>Sales</strong>
              <p>Questions or queries? Get in touch!</p>
              <a href="mailto:">sales@trippay.com</a>
            </li>
            <li className="contact-item">
              <strong>Phone</strong>
              <p>Mon-Fri from 8am to 5pm.</p>
              <a href="mailto:">+1 (555) 000-0000</a>
            </li>
          </ul>
          <img src={GoogleMapImage} alt="" className="google-map-img"></img>
          <ContactForm />
        </div>
      </section>
    </Fragment>
  );
}

export default About;
