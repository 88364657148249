import React from "react";
import NortonLogo from "../img/Norton logo 1.svg";
import McAfeeLogo from "../img/McAfee_logo_(2017) 1.svg";
import FooterLogo from "../img/footer_logo.svg";
import AppStoreBadge from "../img/Mobile app store badge.svg";
import PlayStoreBadge from "../img/Mobile app store badge-1.svg";
import AppGalleryBadge from "../img/Mobile app store badge-2.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="inner-container">
        <div className="header">
          <div className="header-text">
            <h1>Be the first to know when we launch</h1>
            <p>
              We’re still building. Subscribe for updates and 20% off when we
              launch. No spam, we promise!
            </p>
          </div>
          <div className="apps">
            <img src={AppStoreBadge} className="app-download" alt=""></img>
            <img src={PlayStoreBadge} className="app-download" alt=""></img>
            <img src={AppGalleryBadge} className="app-download" alt=""></img>
          </div>
        </div>
        <hr />
        <div className="footer-menu">
          <div className="company-info">
            <img src={FooterLogo} className="footer-logo" alt=""></img>
            <span className="address">
              Riyadh, Saudi Arabia
              <br />
              San Diego, California, USA
              <br />
              <br />
              info@trippay.com
              <br />
              800202656
            </span>
          </div>
          <div className="footer-nav">
            <div className="footer-nav-item">
              <strong>Our Services</strong>
              <ul>
                <li>
                  <a href="/">Overview</a>
                </li>
                <li>
                  <a href="/Business">Business</a>
                </li>
                <li>
                  <a href="/Partnership">Partner</a>
                </li>
                <li>
                  <a href="/">Tutorials</a>
                </li>
                <li>
                  <a href="/">Pricing</a>
                </li>
              </ul>
            </div>
            <div className="footer-nav-item">
              <strong>Company</strong>
              <ul>
                <li>
                  <a href="/About">About us</a>
                </li>
                <li>
                  <a href="/Careers">Careers</a>
                </li>
                <li>
                  <a href="/">Cards</a>
                </li>
                <li>
                  <a href="/">News</a>
                </li>
                <li>
                  <a href="/">Marketplace</a>
                </li>
              </ul>
            </div>
            <div className="footer-nav-item">
              <strong>About</strong>
              <ul>
                <li>
                  <a href="/">Offices</a>
                </li>
                <li>
                  <a href="/">Licences</a>
                </li>
                <li>
                  <a href="/">FAQ</a>
                </li>
                <li>
                  <a href="/Help">Help Center</a>
                </li>
                <li>
                  <a href="/">APIs</a>
                </li>
              </ul>
            </div>
            <div className="footer-nav-item">
              <strong>Legal</strong>
              <ul>
                <li>
                  <a href="/">Terms</a>
                </li>
                <li>
                  <a href="/">Privacy</a>
                </li>
                <li>
                  <a href="/Legal">Cookies</a>
                </li>
                <li>
                  <a href="/">Licences</a>
                </li>
                <li>
                  <a href="/Contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="security-note">
          <h1>for security check , we are applying for the certificates</h1>
          <img src={NortonLogo} className="security-certificat" alt=""></img>
          <img src={McAfeeLogo} className="security-certificat" alt=""></img>
        </div>
        <hr />
        <div className="copyright">
          <p>TripPay © All Rights Reserved, site version 2.34.120</p>
          <ul className="social-media">
            <li className="social-media-item social-media-snapchat">
              <a href="/">Snapchat</a>
            </li>
            <li className="social-media-item social-media-tiktok">
              <a href="/">TikTok</a>
            </li>
            <li className="social-media-item social-media-youtube">
              <a href="/">Youtube</a>
            </li>
            <li className="social-media-item social-media-x">
              <a href="/">X</a>
            </li>
            <li className="social-media-item social-media-linkedin">
              <a href="/">LinkedIn</a>
            </li>
            <li className="social-media-item social-media-facebook">
              <a href="/">Facebook</a>
            </li>
            <li className="social-media-item social-media-dribbble">
              <a href="/">Dribbble</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
